/**
 * PhotoRow
 */

import React from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import { Link, graphql } from 'gatsby';
import convertWpUrlToPath from '@utils/convertWpUrlToPath';
import { Image as Img } from "@components";

const PhotoRow = ({ images, has_border }) => {
  const hasBorder = !!has_border;

  if (!Array.isArray(images) || !images.length) {
    return null;
  }

  return (
    <div className={classnames('page__block--photo-row', {'has-border': hasBorder})}>
      {images.map((image, index) => {
        let Element = 'div';
        const fluid = get(image, 'image.localFile.childImageSharp.fluid', null);
        const alt = get(image, 'alternative_text', '');
        const path = convertWpUrlToPath(get(image, 'link_to_page', ''));

        if (!fluid) {
          return null;
        } 

        // Image should be clickable
        if (path) {
          Element = Link;
        }

        return (
          <Element to={path || null} className={classnames('photo-row__image')} key={index}>
            <Img
              className="img"
              alt={alt}
              fluid={fluid}
            />
          </Element>
        )
      })}
    </div>
  );
}

export default PhotoRow;

export const pageBuilderPhotoRowQuery = graphql`
  fragment PageBuilderPhotoRow on WordPressAcf_photo_row {
    ... on WordPressAcf_photo_row {
      has_border
      images {
        alternative_text
        image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        link_to_page
      }
    }
  }
`